<template>
  <div class="grid grid-cols-1 lg:grid-cols-2">
    <static-text />

    <div
      class="m-0 w-full item-direction md:my-16 md:mx-auto md:w-3/5 lg:mx-0 lg:w-4/5 xl:max-w-md"
    >
      <custom-card
        title="Recuperação de Senha"
        descriptionTitle="Digite abaixo seu e-mail e clique em recuperar a senha. Você receberá um e-mail com o link de recuperação de senha."
        class="py-4"
      >
        <ValidationObserver v-slot="{ invalid }">
          <form @submit.prevent="sendRecoveryEmail()">
            <ValidationProvider
              name="E-mail"
              rules="required|email"
              v-slot="{ errors }"
            >
              <vs-input
                type="email"
                name="email"
                size="large"
                label="E-mail do usuário"
                v-model="email"
                class="w-full mt-8"
              />
              <span class="text-danger text-sm" v-show="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <vs-button
              :disabled="invalid"
              class="w-full mt-24 mb-12"
              size="large"
              @click="sendRecoveryEmail()"
            >
              Enviar link de recuperação
            </vs-button>
          </form>
        </ValidationObserver>
      </custom-card>
    </div>
  </div>
</template>
<script>
import CustomCard from "../../layouts/components/custom-card/CustomCard.vue";
import StaticText from "../../layouts/components/static-text/StaticText.vue";

import instance from "../../axios";


export default {
  components: {
    CustomCard,
    StaticText
  },

  data() {
    return {
      email: ""
    };
  },
  methods: {
    sendRecoveryEmail() {
      this.$onpoint.loadingModal("Processando sua solicitação", () => {
        const payload = {
          Username: this.email
        };

        return instance({
          method: "post",
          url: "/api/Security/RequestRecoveryPassword",
          data: payload
        })
          .then(() => {
            this.$onpoint.successModal(
              "Link de recuperação de senha enviado. Enviamos um link de recuperação de senha para o seu e-mail."
            );
            setTimeout(() => {
              this.$router.push("login");
            }, 4000);
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    }
  },
  created() {
    this.$appInsights.trackPageView({
      name: "page-recuperacao-da-senha",
      url: window.location.href
    });
  }
};
</script>
<style lang="scss" scoped>
.item-direction {
  justify-self: end;
}
</style>
